import React,{useState} from 'react';
// import image2 from './image2.jpg';
import image3 from './image3.jpg';
import whatsApp from './whatsAPp.png';
import Tutoring from "./Gemini_Generated_Image_a3msk6a3msk6a3ms.jpeg";
import './App.css';
import Skills from './skill';
function Project({ github,id,img_path, name, description,used }) {
    let img = img_path;
    let [toggle, setToggle] = useState(false);
    let [toggle2, setToggle2] = useState(false);
    let [toggle3, setToggle3] = useState(false);
    function toggleFunc(numb) {
        if (numb === 2) {
            setToggle2(!toggle2);
        }
        else if (numb === 3) {
            setToggle3(!toggle3);
        }
        else{
        setToggle(!toggle);
        }
    }
    function NewTab(github) {
        window.open(
        github, "_blank");
    }
    return (
        <div className='w-96'>
            <div className={`h-96 w-96 flex flex-col  justify-center items-center ${toggle ? 'flipped' : 'unflipped'}`} onClick={()=>{NewTab(github)}} style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain'
        ,textShadow: '2px 2px 4px rgba(0,0,0,0.9)', fontWeight:'bold', color:'white',backgroundRepeat:'no-repeat',
        // paddingBottom:'100px'
        // height:'300px',width:'400px'
    }}
        onMouseEnter={toggleFunc}
    onMouseLeave={toggleFunc}>
                <>{!toggle ?
                <h1 className="text-xl align-middle self-center text-center">{name}</h1> :
                <p className='GFG align-middle self-center text-center tt'>{used}</p>
                }
                </>
            </div>
        </div>

    );
}

function Carousel({Array}){
    
    return(
        <div className='flex flex-row justify-center  items-center h-full '>
            
            <div
                className="border-solid h-full w-4/5 border-0 mr-10 ml-10 border-black pl-2 pr-2 pt-2 overflow-hidden">
                <ul className="flex flex-row projects justify-center mx-10 px-10 items-center">
                    {Array.map((project) => (
                        <li key={project.id} className="px-5">
                            <Project github={project.github} id={project.id} name={project.name} description={project.description} img_path={project.img_path} used={project.used}/>
                        </li>
                    ))}
                </ul>
            </div>
            
        </div>
    
    )
}
export function Projects(){
    var DATA = [
        {
            "id": 4,
            "name": "WhatsApp Clone",
            "description": "A full-stack messaging application featuring real-time communication with WebSockets, video calling via Stream SDK, and user authentication through Firebase. The platform offers responsive design with custom UI components and maintains <100ms latency for concurrent connections.",
            "img_path": whatsApp,
            "used": "React, Node.js, Express, WebSockets, Stream SDK, Firebase, WebRTC, Render",
            "github": "https://whatsapp-16bff.firebaseapp.com/"
           },
           {
            "id": 5,
            "name": "FundaFuzion Tutoring",
            "description": "A real-time navigation platform for bus systems that leverages geospatial data analysis and live data feeds to provide dynamic route updates and ETA predictions. Features an interactive map interface integrated with public transit APIs and calendar synchronization for optimal route planning.",
            "img_path": Tutoring,
            "used": "Node.js, MongoDB, Geospatial APIs, React, Public Transit APIs, Calendar Integration",
            "github": "https://wright-4d6f6.web.app/"
           },
        // {
        // "id": 1,
        // "name": "PiGesture Assistant",
        // "description": "PiGesture Assistant is a innovative project that enables remote control of Raspberry Pi devices through gesture recognition and speech commands. With PiGesture Assistant, users can effortlessly navigate menus, control media playback, and execute commands using simple gestures and voice commands. Experience a new level of convenience and interaction with your Raspberry Pi with PiGesture Assistant.        ",
        // "img_path": "https://rpiblogs.com/uploads/images/2024/01/image_750x_65a015b5887a4.jpg",
        // "used": "Python, OpenCV, Google Speech Recognition, PyAudio, RPi.GPIO, RPi.GPIO",
        // 'github': "https://github.com/Amsozzer1/Projector"
    
        // }
        // ,{
        // "id": 2,
        // "name": "Shell",
        // "description": "Made a shell in C that can run commands and pipe them. Moreover, the shell can run in the background and foreground and it can also run multiple commands at the same time. ",
        // "img_path": image2,
        // "used": "C, Linux, Unix, Shell, System Calls, Fork, Pipe, Dup2, Signal Handling, Background and Foreground Processes",
        // 'github': "https://github.com/illinois-cs-coursework/sp24_cs341_asozzer2/tree/main/shell"
        
        // },
        {
        "id": 3,
        "name": "NavBus",
        "description": "NavBus is a web application that provides real-time bus tracking and navigation services for the University of Illinois Urbana Champaign, College Park. NavBus allows users to track the location of buses in real-time, view bus routes and schedules, and receive notifications for bus arrivals and departures. With NavBus, users can easily plan their commutes and stay informed about the status of their bus routes. NavBus is designed to provide a seamless and convenient experience for students, faculty, and staff at UIUC",
        "img_path": image3,
        "used": "React, Node.js, Express, MongoDB, Google Maps API, Google Cloud Platform, Firebase, Material-UI, HTML, CSS, JavaScript, Git, GitHub",
        "github":'https://github.com/Amsozzer1/CS409--FINAL'
    }
]
    return (
        <div className="mt-10 h-fit w-100 Projects relative" style={{top:'-100px'}}>
            <h1 style={{fontSize:'35px' ,color:'white'}} className="pb-1 text-center">My Recent Works</h1>
            <Carousel Array={DATA}/>
            <div className="pt-20">
                <Skills />
            </div>
            
        </div>
    );  
}
